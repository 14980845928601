(function(w, d) {
  var feedback = {};

  var $body = $('body');
  var isInit = false;

  var $feedForm = $('.feedback');
  var $debugInfo = $('#debug_info');
  var $reportForm = $feedForm.find('.report-a-problem');
  var $textQuestion = $feedForm.find('.feedback-text');
  var $feedbackActions = $feedForm.find('.feedback-actions');
  var $ratingYes = $feedForm.find('.rating-positive');
  var $ratingPanel = $feedForm.find('.feedback-rating');
  var $ratingComm = $feedForm.find('.rating-common');

  var yesCounter = 0;
  var commCounter = 0;
  var scrollPosition = document.body.scrollTop +
    document.documentElement.scrollTop;

  var $reportProblemDialog = $('#report-a-problem');
  var $feedbackReportAction = $('.feedback-report-action');
  var $reportType = $reportProblemDialog.find('select');
  var $reportDescription = $reportProblemDialog.find('textarea');

  var $feedbackScrollBtn = $('.feedback-scroll-btn');

  feedback.hideVoting = function() {
    $textQuestion.addClass('hide');
    $feedbackActions.addClass('hide');
  };

  feedback.showVoting = function() {
    $textQuestion.removeClass('hide');
    $feedbackActions.removeClass('hide');
  };

  feedback.hideRating = function() {
    $ratingPanel.addClass('hide');
  };

  feedback.showRating = function() {
    $ratingPanel.removeClass('hide');
  };

  feedback.getSelectionText = function() {
    var text = '';
    if (window.getSelection) {
      text = window.getSelection().toString();
    }
    else if (document.selection && document.selection.type != 'Control') {
      text = document.selection.createRange().text;
    }
    return text;
  };

  feedback.getClearArticlePath = function() {
    var href = window.location.pathname, hrefParts;

    hrefParts = href.split('/');
    hrefParts.splice(0, 4);
    href = '/' + hrefParts.join('/');

    return href;
  };

  feedback.sendReport = function(type, description) {
    $.ajax({
      dataType: 'json',
      data: {
        url: window.location.href,
        type: type,
        description: description
      },
      method: 'post',
      url: '/ajax/common/feedback/report',
      success: function(resp) {}
    });
  };

  feedback.getFeedbacks = function() {
    feedback.hideVoting();
    feedback.hideRating();

    $.ajax({
      dataType: 'json',
      method: 'post',
      data: {
        path: feedback.getClearArticlePath()
      },
      url: '/ajax/common/feedback',
      success: function(resp) {
        if (resp.result === 'ok') {
          yesCounter = resp.positive;
          commCounter = resp.common;
          $ratingComm.html(resp.common);
          $ratingYes.html(resp.positive);

          if (!resp.isVoted) {
            feedback.showVoting();
          }

          if (resp.hasRating) {
            feedback.showRating();
          }
        }
      }
    });
  };

  feedback.getFeedbacksCommonStats = function() {
    $.ajax({
      dataType: 'json',
      method: 'post',
      url: '/ajax/common/feedback-common-stats',
      success: function(resp) {
        if (resp.result === 'ok') {
        }
      }
    });
  };

  feedback.addFeedback = function(type) {
    //positive = 1
    //negative = -1
    $.ajax({
      dataType: 'json',
      data: {
        type: type,
        path: feedback.getClearArticlePath()
      },
      method: 'post',
      url: '/ajax/common/feedback/add',
      success: function(resp) {}
    });
  };

  feedback.initFeedbackScrollBtn = function() {
    if ($feedForm.length && $feedbackScrollBtn.length) {
      $(window).on('load resize scroll', function() {
        scrollPosition = document.body.scrollTop +
          document.documentElement.scrollTop;

        if (scrollPosition > $feedForm.position().top - 700) {
          $feedbackScrollBtn.addClass('hide');
        }
        else {
          $feedbackScrollBtn.removeClass('hide');
        }
      });
    }
  };

  feedback.init = function(cb) {
    feedback.getFeedbacks();

    if ($feedForm && !isInit) {
      $feedForm.find('.btn-yes').click(function() {
        yesCounter++;
        commCounter++;

        $ratingYes.html(yesCounter);
        $ratingComm.html(commCounter);

        feedback.hideVoting();
        feedback.showRating();

        feedback.addFeedback(1);
      });

      $feedForm.find('.btn-no').click(function() {
        commCounter++;
        $ratingComm.html(commCounter);

        feedback.hideVoting();
        feedback.showRating();

        feedback.addFeedback(-1);
      });

      feedback.initFeedbackScrollBtn();
    }

    if ($reportForm && !isInit) {
      $reportProblemDialog.find('.close-button').click(function() {
        window.Unigine.hideProblemDialog();
      });

      $reportProblemDialog.on('click', function(e) {
        if (e.target === $reportProblemDialog[0]) {
          window.Unigine.hideProblemDialog();
        }
      })

      $feedbackReportAction.click(function(e) {
        e.preventDefault();
        window.Unigine.showProblemDialog();
        var selText = feedback.getSelectionText();
        if (selText) {
          $reportType.val('spelling').change();
          $reportDescription.val(selText);
        }
      });

      $reportProblemDialog.modal({
        show: false,
        keyboard: false,
        backdrop: 'static',
      }).find('.save-button').click(function() {
        var $reportTypeSelected = $reportProblemDialog.find(
            'select option:selected'),
            $honeypotCheckbox = $reportProblemDialog.find(
            '[name=hFax]').prop('checked');

        if (!$reportDescription.val()) {
          $reportDescription.parent().addClass('has-error');
          return false;
        }

        if (!$honeypotCheckbox) {
          feedback.sendReport($reportTypeSelected.text(), $reportDescription.val());
          window.Unigine.hideProblemDialog();
        }
      });

      window.Unigine.showProblemDialog = function(success) {
        $reportProblemDialog.modal('show');
      };

      window.Unigine.hideProblemDialog = function() {
        $reportProblemDialog.modal('hide');
      };

      // "Select text & press Ctrl+Enter" functionality
      $body.keypress(function(e) {
        if ((e.ctrlKey) && ((e.keyCode == 0xA) || (e.keyCode == 0xD))) {
          const selectedText = feedback.getSelectionText();
          const isModalOpen = $('body').hasClass('modal-open');

          if (isModalOpen || selectedText.length === 0) {
            return;
          }

          $reportType.val('spelling').change();
          window.Unigine.showProblemDialog();
          $reportDescription.val(selectedText);
        }
      });
    }
    
    if ($debugInfo.length && !isInit) {
      var getStatsBtn = $('<a/>',{
        text: 'Update stats',
        class: 'feedback-btn-common-stats'
      });

      getStatsBtn.on('click', function(){
        feedback.getFeedbacksCommonStats();
      })

      $debugInfo.prepend(getStatsBtn);
    }

    isInit = true;
  };

  w.feedback = feedback;
})(window, document);
